import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, filter, finalize, map, tap } from 'rxjs';
import { LoginSuccessObject, UserDataObject } from '../interface/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl = 'https://shop.theworkshop.link/api';

  constructor(private http: HttpClient) {}

  loginUser(payload: {
    email: string;
    password: string;
  }): Observable<LoginSuccessObject> {
    return this.http
      .post<LoginSuccessObject>(`${this.baseUrl}/login`, payload)
      .pipe(
        filter((result) => !!result),
        tap((result) => this.setUserSession(result))
      );
  }

  registerUser(payload: {
    first_name: string;
    last_name: string;
    email: string;
    referral_code: string;
    password: string;
    password_confirmation: string;
    _token: string;
  }): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/register`, payload).pipe(
      filter((result) => !!result),
    );
  }

  logoutUser() {
    return this.http.post(`${this.baseUrl}/logout`, {});
  }

  setUserSession(user: LoginSuccessObject) {
    localStorage.setItem('token', JSON.stringify(user.token));
    localStorage.setItem('user', JSON.stringify(user.user));
  }

  getSessionToken(): string {
    return JSON.parse(localStorage.getItem('token'));
  }

  isAuthenticated(): boolean {
    return !!JSON.parse(localStorage.getItem('token'));
  }

  getUserSession(): UserDataObject {
    return JSON.parse(localStorage.getItem('user')) as UserDataObject;
  }
}
