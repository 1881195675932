export const environment = {
  production: false,
  imageBaseUrl: 'https://shop.theworkshop.link/public/',
  shippingMethodToken:
    'eyJpdiI6ImR1dGhuVDRxRzdyWlcvVG4zRnFYSkE9PSIsInZhbHVlIjoiYkk2RVhTMDhybW40VTJsNTNHUHNaUT09IiwibWFjIjoiNmZjNjBkZGM1OWRiYjhjYjU2OTg3MWU3MjlhYjUxZGI0MjRkOGIyY2M1ZWQ3YjAyNjA4OGQ5M2MyODExYmNkNiIsInRhZyI6IiJ9',
  gateWayId:
    'eyJpdiI6IkRNZVpNVGpObWhaMlFxTGNwSWsvK0E9PSIsInZhbHVlIjoiUTFwUldHWm1IQTF0KzlZU1BsZDJ0UT09IiwibWFjIjoiNmE2N2FmZjk2ZWI0ODgzYTU2MGUyZjFmOTYyOWY2ODNmMjUzNDlhYjAyMjg4NTMyM2IzMDM2Zjc4ZmU1MTYyMyIsInRhZyI6IiJ9',
  paymentId:
    'eyJpdiI6Im1XQ3hvRXBQOVkxVTUxQmJpQWlCUXc9PSIsInZhbHVlIjoiR2d0VHFRS3YzdVBMZm5VUXFsRDJOdz09IiwibWFjIjoiOGVhNzZlNGI0Yjg4ZWQ1NzA3ZDVkZTMxMzFjYzAyNzcwMjc1YzM0YzU0NWIxYjViM2JlZmI0N2IyMGNiNTQ0MCIsInRhZyI6IiJ9',
};
