import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./pages/pages.module').then(module => module.PagesModule)
  },
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: "",
    redirectTo: "",
    pathMatch: "full"
  }
];

const options: ExtraOptions = {
  useHash: true,
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled"
}

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
