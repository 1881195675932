import { Component } from '@angular/core';

@Component({
  selector: 'd-shine',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'D-shine';

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
